// a utility class for altering the visibility/typing of a password field
//      utilizing event listeners & a passed element

function toggleVisibility(elem){
    var iconElem = elem.parentElement.querySelector("#visibility_icon") 

    if (elem.tagName.toString().toUpperCase() != "INPUT") {
        console.error("Unable to toggle visibility for " + elem.id + " as it is not a compatible tag (input element)")
    } else {
        if ( elem.getAttribute('type') == "password" ){
            elem.setAttribute('type', 'text')
            iconElem.classList.remove("fa-eye")
            iconElem.classList.add("fa-eye-slash")
        } else if ( elem.getAttribute('type') == "text" ) {
            elem.setAttribute('type', 'password')
            iconElem.classList.remove("fa-eye-slash")
            iconElem.classList.add("fa-eye")
        } else {
            console.error("Unable to toggle visibility for " + elem.id + " as it is not a compatible type (text/password)")
        }
    }
}

// appends our FA icon button for toggling the field's visibility to the passed element
function instantiateVisibilityIcon(targetElem) {
    var iconElem = targetElem.parentElement.querySelector("#visibility_icon") 

    if (iconElem == null){
        var _newIcon = document.createElement("i");
        _newIcon.setAttribute('class', "fas fa-eye login-field-fas-visibility") 
        _newIcon.setAttribute('id', "visibility_icon")
        targetElem.after(_newIcon);
        iconElem = _newIcon;
    }
    
    return iconElem
}

export function addVisibilityListener(targetElem){
    var _icon = instantiateVisibilityIcon(targetElem);

    _icon.addEventListener("click", function(){
        toggleVisibility(targetElem);
    });
}


